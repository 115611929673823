<template>
  <div class="text-center vh-100 justify-content-center d-flex align-items-center">
    <div v-if="!user">
      <InputGroup v-model="name"
                  :label="$t('name')"/>
      <b-button class="btn-themed w-100"
                @click="submit"
                :loading="loading"
                :disabled="!name">
        {{ $t('continue') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import InputGroup from "../../parts/general/form/InputGroup.vue"
import { UsersService } from "../../../services/api.service"
import AuthService from "@/services/auth.helper"

export default {
  name: "UnauthorizedToLesson",
  components: {
    InputGroup
  },
  data() {
    return {
      name: '',
      loading: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    code() {
      return this.$route.params.code
    },
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    async submit() {
      try {
        this.loading = true
        const res = await UsersService.loginUnauthorized(this.id, this.code, this.name)
        if(res.data.data.user.api_token) {
          const user = res.data.data.user
          AuthService.login({
            expires_in: 43200 * 60,
            token_expires_in: user.api_token_expires_at,
            access_token: user.api_token,
            refresh_token: user.api_token,
          }, user)
          this.$store.commit('saveUser', {
            user,
            vm: this
          })
          await this.$store.dispatch('syncTimeZone')
          await this.goToLesson()
        }
      } catch (e) {
        this.$error(e.toString())
      } finally {
        this.loading = false
      }
    },
    async goToLesson() {
      await this.$router.push({
        name: 'Lesson',
        params: {
          id: this.id,
          code: this.code
        }
      })
    }
  },
  mounted() {
    if(this.user) {
      this.goToLesson()
    }
  }
}
</script>
